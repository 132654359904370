import { TimelineLite, TweenMax } from "gsap";
import Flickity from "flickity";
require("flickity-imagesloaded");
require("./swapping");
import Splitting from "splitting";

Splitting();
const highlighters = document.querySelectorAll("[data-highlights]");
const highlighteds = document.querySelectorAll("[data-highlight]");
const persons = document.querySelector(".persons");

let mouse = { x: 0, y: 0 };

Array.prototype.forEach.call(highlighters, function (highlighter, index) {
  highlighter.addEventListener("mouseover", function (e) {
    const toSelect = highlighter.dataset.highlights.split(",");
    highlightThese(toSelect);
  });
  highlighter.addEventListener("mouseleave", function (e) {
    TweenMax.to(highlighter, 0.3, {
      x: 0,
      y: 0,
    });
  });
  highlighter.addEventListener("mousemove", function (e) {
    const squareData = highlighter.getBoundingClientRect();
    mouse.x = e.clientX - squareData.left - squareData.width / 2;
    mouse.y = e.clientY - squareData.top - squareData.height / 2;
    parallax(highlighter, -10, squareData);
  });
});

// TweenMax.from(document.querySelector(".logo-image"), {
//   duration: 5,
//   yoyo: true,
//   repeat: -1,
//   color: "#ffa500",
// });

persons.addEventListener("mouseleave", function (e) {
  highlightThese([]);
});

function highlightThese(toSelect) {
  Array.prototype.forEach.call(highlighteds, function (highlighted) {
    if (toSelect.indexOf(highlighted.dataset.highlight) > -1) {
      highlighted.classList.add("is-highlighted");
    } else {
      highlighted.classList.remove("is-highlighted");
    }
  });
}

function parallax(target, mvmt, squareData) {
  TweenMax.to(target, 0.3, {
    x: ((mouse.x - squareData.width / 2) / squareData.width) * mvmt,
    y: ((mouse.y - squareData.height / 2) / squareData.height) * mvmt,
  });
}

new Flickity(".clients", {
  imagesLoaded: true,
  cellAlign: "left",
  wrapAround: true,
  pageDots: false,
});

const appearance = new TimelineLite();

appearance
  .staggerFrom(
    document.querySelectorAll(
      ".main h1 .line > .word > .char, .main h1 .line > .whitespace, .main h1 .text-anim__word--in > .whitespace, .main h1 .text-anim__word--in > .word > .char"
    ),
    0.5,
    {
      ease: "Power3.easeOut",
      y: "-100%",
      opacity: 0,
    },
    0.014,
    "hero"
  )
  .staggerFrom(
    document.querySelectorAll("[data-highlights] > img"),
    1,
    {
      ease: "elastic.out(1, 0.6)",
      y: -50,
      opacity: 0,
    },
    0.1,
    "-=0.5"
  )
  .staggerFrom(
    document.querySelectorAll(".person-name"),
    1,
    {
      ease: "elastic.out(1, 0.6)",
      opacity: 0,
    },
    0.1,
    "-=1.5"
  )
  .from(
    document.querySelectorAll(".fadeIn"),
    1,
    {
      opacity: 0,
    },
    "-=1"
  );
